button,
input,
optgroup,
select,
textarea {
	font-family: $sans-serif-family;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: $font-weight-normal;
}

small {
	font-size: 60%;
}

a {
	color: $primary-color;
	&:hover,
	&:focus {
		color: $primary-color;
	}
}
h1,
.h1 {
	font-size: $font-size-h1;
	line-height: 1.15;
	margin-bottom: $margin-base-vertical * 2;

	small {
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		opacity: 0.8;
	}
}
h2,
.h2 {
	font-size: $font-size-h2;
	margin-bottom: $margin-base-vertical * 2;
}
h3,
.h3 {
	font-size: $font-size-h3;
	margin-bottom: $margin-base-vertical * 2;
	line-height: 1.4em;
}
h4,
.h4 {
	font-size: $font-size-h4;
	line-height: 1.45em;
	margin-top: $margin-base-vertical * 2;
	margin-bottom: $margin-base-vertical;

	& + .category,
	&.title + .category {
		margin-top: -5px;
	}
}
h5,
.h5 {
	font-size: $font-size-h5;
	line-height: 1.4em;
	margin-bottom: 15px;

	&.category {
		font-weight: 400;
	}
}
h6,
.h6 {
	font-size: $font-size-h6;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
}
p {
	line-height: 1.61em;
	font-weight: 300;
	font-size: 1.2em;
}

.title {
	font-weight: $font-weight-bold;
	padding-top: 30px;

	&.title-up {
		text-transform: uppercase;

		a {
			color: $black-color;
			text-decoration: none;
		}
	}
	& + .category {
		margin-top: -25px;
	}
}

.description,
.card-description,
.footer-big p {
	color: $dark-gray;
	font-weight: $font-weight-light;
}
.category {
	text-transform: capitalize;
	font-weight: $font-weight-bold;
	color: $dark-gray;
}
.text-primary {
	color: $brand-primary !important;
}
.text-info {
	color: $brand-info !important;
}
.text-success {
	color: $brand-success !important;
}
.text-warning {
	color: $brand-warning !important;
}
.text-danger {
	color: $brand-danger !important;
}
.text-black {
	color: $light-black;
}

.blockquote {
	border-left: none;
	border: 1px solid $default-color;
	padding: 20px;
	font-size: $font-size-blockquote;
	line-height: 1.8;

	small {
		color: $default-color;
		font-size: $font-size-small;
		text-transform: uppercase;
	}

	&.blockquote-primary {
		border-color: $primary-color;
		color: $primary-color;

		small {
			color: $primary-color;
		}
	}

	&.blockquote-danger {
		border-color: $danger-color;
		color: $danger-color;

		small {
			color: $danger-color;
		}
	}

	&.blockquote-white {
		border-color: $opacity-8;
		color: $white-color;

		small {
			color: $opacity-8;
		}
	}
}
