.fontFamily {
	font-family: 'Playfair Display' !important;
}
.floatLeft {
	float: left;
}
.floatRigth {
	float: right;
}
.displayFlex {
	display: flex;
}
.marginLeft5px {
	margin-left: 5px !important;
}
.marginRight5px {
	margin-right: 5px !important;
}
.marginLeft10px {
	margin-left: 10px !important;
}
.marginRight10px {
	margin-right: 10px !important;
}
.marginLeft20px {
	margin-left: 20px !important;
}
.marginRight20px {
	margin-right: 20px !important;
}
.height500px {
	height: 500px !important;
}
.height100px {
	height: 100px !important;
}
.height440px {
	max-height: 440px !important;
}
.carousel-demo .product-item .product-item-content {
	border: 1px solid var(--surface-d);
	border-radius: 3px;
	margin: 0.3rem;
	text-align: center;
	padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
	width: 50%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.height50 {
	height: 50% !important;
}

.height200 {
	height: 200px !important;
}
.width100 {
	width: 100% !important;
}
.width20 {
	width: 40px !important;
}
.width20x {
	width: 20px !important;
}
.margin-bottom0 {
	margin-bottom: 0px !important;
}
.marginTop20 {
	margin-top: 20px;
}
.margingTop50 {
	margin-top: 50px;
}
.flex {
	display: flex;
	flex-wrap: wrap-reverse;
}
.flexWrap {
	display: flex;
	flex-wrap: wrap;
}
.padding11 {
	padding: 15px !important;
}
.padding-bottom0 {
	padding-bottom: 0px;
}
.padding-top0 {
	padding-top: 0px;
}
.textdecorationunderline {
	text-decoration: underline;
}
.fontweightbold {
	font-weight: bold;
}
.borderradius30 {
	border-radius: 30px !important;
}
.margin0 {
	margin: 0px !important;
}

.p-md-5 {
	padding: 0px !important;
}

.loaddingCenter {
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}
.amenitiesCenter {
	margin-left: auto;
	margin-right: auto;
}
.wrapper {
	font-family: playfair;
}
.center {
	text-align: center;
}

h4 {
	margin-top: 0px !important;
}
.black {
	color: black !important;
}
.white {
	color: white !important;
}

.borderRadius2 {
	border-radius: 2rem;
}
.p-button {
	background: #959595;
	border-color: 1px solid #959595 !important;
	&:hover {
		background-color: #959595 !important;
		border-color: 1px solid #959595 !important;
	}
	&:focus {
		background-color: #959595 !important;
		border-color: 1px solid #959595 !important;
	}
	&:active {
		border-color: 1px solid #959595 !important;
	}
}

.btn-primary {
	background-color: grey !important;
	border: 1px solid grey !important;
}
.p-button {
	color: #ffffff;
	background: grey !important;
	border: 1px solid grey !important;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
	background-color: grey;
}
.footer {
	padding: 0px 0 !important;
}
.font25px {
	font-size: 25px !important;
}

.section-story-overview div.blockquote {
	width: 255px;
	min-height: 180px;
	text-align: left;
	position: absolute;
	top: 376px;
	right: 155px;
	z-index: 0;
}
.nocolor {
	color: white !important;
}

.p-connected-overlay-enter-done {
	// top: 80px !important;
}

.font-size-12 {
	font-size: 12px !important;
}
.width-80px {
	width: 80px;
}

.width-120px {
	width: 120px;
}

.page-login-image:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.6);
}

.logo-container1 {
	width: 219px;
	margin: 0 auto;
	margin-bottom: -89px;
}

.description p {
	margin-bottom: 0px;
	font-size: 1em;
}
.fixed-top {
	z-index: 500 !important;
}
