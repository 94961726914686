.bandera {
	margin-right: 10px;
}
.navbar-transparent .navbar a:not(.btn):not(.dropdown-item) {
	color: black !important;
}

ul li {
	font-size: 20px;
}
.fontFamily {
	font-family: 'Playfair Display' !important;
}

.contenedor {
	top: -2px;
	width: 220px;
	height: 50px;
	overflow: hidden;
	position: relative;
	margin-right: -25px;
	margin-left: -50px;
}
.contenedor > .crop {
	position: absolute;
	left: -100%;
	right: -100%;
	top: -60%;
	bottom: -100%;
	margin: auto;
	min-height: 100%;
	min-width: 100%;
}
