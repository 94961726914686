.carrusel {
	height: inherit;
	margin: 0 10px 20px 20px;
}

.separador {
	height: 0px !important;
}

.carruselDiv {
	display: flex;
	//align-items: center;
	justify-content: center;
}
.div2 {
	display: flex;
	/* align-items: center; */
	justify-content: center;
}
.leaflet-container {
	width: 100%;
	height: 350px;
}

.seccion {
	text-transform: uppercase;
	font-size: 14px;
	font-family: gotham;
	font-weight: 300;
	color: #aaa;
	padding: 25px 5px !important;
	letter-spacing: 2px;
	text-align: left;
}
.p-accordion-header-text {
	text-transform: uppercase;
	font-size: 14px;
	font-family: gotham;
	font-weight: 300;
	color: #aaa;
	letter-spacing: 2px;
}
.p-accordion-header-link {
	background-color: white !important;
}
.row {
	margin-right: 0px !important;
	margin-left: 0px !important;
}
.amenitie {
	padding-right: 5px;
	font-size: 14px;
	color: #757575;
	font-weight: 700;
}

h5 {
	.textButton {
		background-color: transparent !important;
		color: #2196f3 !important;
		border-color: transparent !important;
		&:hover {
			background-color: transparent !important;
			color: #2196f3 !important;
			border: 0px transparent !important;
		}
		&:focus {
			background-color: transparent !important;
			color: #2196f3 !important;
			border: 0px transparent !important;
		}
		&:active {
			background-color: transparent !important;
			color: #2196f3 !important;
			border: 0px transparent !important;
		}
	}
}
.read-more-less {
	&:hover {
		cursor: pointer;
	}
}
