.separador {
	height: 100px !important;
}

.collection-preview {
	display: flex;
	margin-bottom: 30px;
	align-items: center;
	justify-content: center;
	padding: 0.1%;
	flex-wrap: wrap;
}

.pisoPrev {
	margin: 15px;

	.h1 {
		text-align: center;
	}
}
