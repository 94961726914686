.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.switch{
    margin: 5px;
    text-align: right;    
}

.switch label{
    margin-right: 20px;
    margin-bottom: 10px;
    transform: translate3d(0px, -10px, 0px);
}

#esteId{
    margin-top: 65px;
    max-height: 100%;
}

    .p-component{
        font-size: 13px !important;  
        font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif !important;  
    }

    .p-datatable .p-datatable-thead > tr > th {
        text-align: center !important;
        font-weight: 510 !important;
        color: #495057 !important;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        text-align: center !important;
    }

    .linea{
        width: 1px;
        height: 100%;
        background-color:#dbe0e6;
        margin: 0px 15px;
        float:left;
    }

    .ant-upload-list-picture-card-container{
        float: left !important;
    }
    .p-lg-5 {
        padding: 0.5rem !important;
    }